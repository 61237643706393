.App {
  text-align: center;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 16vw;
  color: white;
  text-decoration: none; 
}